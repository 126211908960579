type FlowAction = 'chat-support' | 'cart';

type FlowParams = {
  flow: FlowAction;
  returnUrl?: string;
};

type FlowHandler = (params: Partial<FlowParams>) => void;

type FlowProcessors = {
  [K in FlowAction]: FlowHandler;
};

const parseFlowQuery = (query: Record<string, string | string[] | undefined>): Partial<FlowParams> => ({
  flow: Array.isArray(query.flow) ? (query.flow[0] as FlowAction) : (query.flow as FlowAction),
  returnUrl: Array.isArray(query.returnUrl) ? query.returnUrl[0] : query.returnUrl,
});

// Helper to create flow processors with type safety
export const createFlowProcessors = (handlers: {
  openChat: () => void;
  openCart: (returnUrl?: string) => void;
}): FlowProcessors => ({
  'chat-support': () => handlers.openChat(),
  cart: (params) => handlers.openCart(params.returnUrl),
});

export const processFlowQuery = (
  query: Record<string, string | string[] | undefined>,
  handlers: {
    openChat: () => void;
    openCart: (returnUrl?: string) => void;
  },
): void => {
  const params = parseFlowQuery(query);

  if (!params.flow) return;

  const flowProcessors = createFlowProcessors(handlers);

  const handler = flowProcessors[params.flow];
  if (handler) {
    handler(params);
  }
};
